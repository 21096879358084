body{
  background-image: url('https://t3.ftcdn.net/jpg/05/27/52/12/240_F_527521201_Yk4ACV2KlfUg8uaUE4wVhg755swm3yym.jpg');

  background-size:  100vw 10000vh;

}

.about-icon {
	display: inline-block;
	transition: transform 0.3s ease;

  }
  
  .about-icon:hover {
	transform: scale(1.2); 
  }

  .custom-btn-primary{
    font-weight: 600;
  }
  .custom-btn-primary:hover{
    background-color: rgb(223, 227, 217);
    color: black;
    }

    .carousel-item img {
      object-fit: contain;
      max-width: 100%;
      max-height: 400px; /* Adjust the max height as needed */
    }
    .CardTitle {
      font-size: 100%; 
    }
    
    
    